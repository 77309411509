import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import SendCode from "./components/SendCode";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/" component={SendCode}></Route>
          </Switch>
        </React.Fragment>
      </HashRouter>
    );
  }
}
export default App;
