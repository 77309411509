export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://www.rateose.store";

export default {
  GET_CODE: "/api/Yonghu/telms",
  LOGIN: "/api/Yonghu/login",
  UPLOAD: "/api/common/upload",
  USERLIST: "/api/Yonghu/getYonghuList",
  SITELIST: "/api/Zhandian/getZhanDianList",
  COSTLIST: "/api/Consumptionschedule/getconsumptionScheduleList",
  ADDUSER: "/api/Yonghu/setYonghu",
  USER: "/api/Yonghu/getYonghu",
  DELETE_USER: "/api/Yonghu/delYonghu",
  USERLIST_NOPAGE: "/api/Yonghu/getYonghuListN",
  STIELIST_NOPAGE: "/api/Zhandian/getZhanDianList",
  ADDSITE: "/api/Zhandian/setZhanDian",
  SITE: "/api/Zhandian/getZhanDian",
  DELETE_SITE: "/api/Zhandian/delZhanDian",
  ADDCOST: "/api/Consumptionschedule/setconsumptionSchedule",
  COST: "/api/Consumptionschedule/getconsumptionSchedule",
  DELETE_COST: "/api/Consumptionschedule/delconsumptionSchedule",
  GET_CHART: "/api/trend/baobiao",
  BANGDAN: "/api/trend/bandanii",
  CONFIG: "/api/Urlrelation/getUrlrelationUrl",
  ORDER: "/api/Urlorder/getOrderList",
  PIXEL: "/api/Events/groupevent",
  AD:'/api/Events/groupad',
  EDIT_CONFIG:'/api/Urlrelation/setUrlrelationII',
  GET_ORDER_DETAIL:'/api/Urlorder/getOrder',
  EDIT_ORDER:'/api/Urlorder/xiugaishijizhuangtai'
};
